@import '@angular/material/theming';

@mixin menu-list-item-theme($primary) {
  app-menu-list-item {
    .mat-list-item.active {
      background-color: #e8eaf6 !important;
      color: $primary !important;
    }

    &:hover,
    &:focus {
      > .mat-list-item:not(.expanded) {
        background-color: #e8eaf6 !important;
        color: $primary !important;
      }
    }
  }
}
