////
/// Padding (Automatic)
/// @author Victor Cruz
/// @access public
/// @group cf-helpers
////

/* Padding (Automatic)
 -------------------------------------------------------------- */

@each $element, $name in (padding, Padding),
    (padding-top, Padding Top),
    (padding-bottom, Padding Bottom),
    (padding-left, Padding Left),
    (padding-right, Padding Right) {
  $i: 0;

  /* #{$name}
 -------------------------------------------------------------- */
  @while $i <= $base-total-padding {
    .#{$prefix-helpers}-#{$element}-#{$i} {
      #{$element} : #{$i}px !important;
    }
    $i: $i + $base-interval-padding;
  }
}

// Example Generate:

//.cf-padding-0 {
//  padding: 0 !important;
//}
//
//__<<ngThemingMigrationEscapedComment2>>__
//.cf-padding-20 {
//  padding: 20px !important;
//}
//
//__<<ngThemingMigrationEscapedComment3>>__
//.cf-padding-40 {
//  padding: 40px !important;
//}
//
//__<<ngThemingMigrationEscapedComment4>>__
//.cf-padding-60 {
//  padding: 60px !important;
//}
//
//__<<ngThemingMigrationEscapedComment5>>__
//.cf-padding-80 {
//  padding: 80px !important;
//}
//
//__<<ngThemingMigrationEscapedComment6>>__
//.cf-padding-100 {
//  padding: 100px !important;
//}

/* Padding Top
-------------------------------------------------------------- */

//.cf-padding-top-0 {
//  padding-top: 0 !important;
//}
//
//.cf-padding-top-20 {
//  padding-top: 20px !important;
//}
//
//.cf-padding-top-40 {
//  padding-top: 40px !important;
//}
//
//.cf-padding-top-60 {
//  padding-top: 60px !important;
//}
//
//.cf-padding-top-80 {
//  padding-top: 80px !important;
//}
//
//.cf-padding-top-100 {
//  padding-top: 100px !important;
//}
