/* You can add global styles to this file, and also import other style files */
@import "swiper/swiper-bundle.min.css";
@import "./assets/styles/variable";
@import "./assets/styles/helpers/cf";
@import "./assets/styles/helpers/helpers.scss";
@import "./assets/styles/helpers/bootstrap-classes";

// regular style toast
@import "ngx-toastr/toastr.css";

$mini-sidebar-width: 80px;

.mat-dialog-content {
  overflow-x: hidden !important;
}

.w-100 {
  width: 100%;
}

.spin {
  animation: spin 2s linear infinite;
}

.spin-reverse {
  animation: spin-reverse 2s linear infinite;
  transform: scaleX(-1);
}

ngu-tile {
  padding: 0 !important;
}

.loading-shade-full {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.7) !important;
  z-index: 1024;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-logo {
  height: 40px !important;
  width: 40px !important;
  font-size: 40px !important;
}

.remove-top-padding {
  padding-top: 0 !important;
}

//
html,
body {
  height: 100%;
  margin: 0;
  font-family: RobotoDraft, Roboto, "Helvetica Neue", sans-serif, "Lato", sans-serif !important;
}

//
span {
  font-family: RobotoDraft, Roboto, "Helvetica Neue", sans-serif, "Lato", sans-serif !important;
}

mat-label {
  //background-color: white !important;
  //padding-right: 3px !important;
}

// .md-drppicker[_ngcontent-c19] .ranges[_ngcontent-c19] ul[_ngcontent-c19] li[_ngcontent-c19] button.active[_ngcontent-c19] {
//   background-color: $main-color !important;
//   color: #fff;
// }

div.md-drppicker {
  margin-bottom: 150px;
  //width: 700px !important;
  //z-index: 10;
}

.custom-button {
  margin-top: 10px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background-color: $red-500 !important;
  color: white !important;
}

.select-column {
  width: 5% !important;
}

.full-width {
  width: 100% !important;
}

.chip-date {
  padding: 5px 15px;
  border: solid 1px $main-color;
  border-radius: 16px;
  background-color: #fafafa;
  color: $main-color;
}

.uppercase {
  text-transform: uppercase !important;
}

.justify {
  text-align: justify !important;
}

.capitalize {
  text-transform: capitalize !important;
}

// .main-color {
//   color: $main-color;
// }

// .background-main-color {
//   background-color: $main-color;
// }

.background-color-white {
  background-color: white;
}

// .my-primary-stroked-button {
//   border: solid 2px $main-color !important;
// }

.my-primary-stroked-button[ng-reflect-disabled="true"] {
  border: solid 2px $grey-300 !important;
}

.my-primary-button {
  mat-icon {
    color: white;
  }

  span {
    color: white;
    text-transform: uppercase !important;
  }
}

.my-stroked-button {
  border: solid 2px $grey-600 !important;

  span {
    color: $grey-600 !important;
    text-transform: uppercase;
  }
}

// .my-warm-stroked-button {
//   border: solid 2px $warn-color !important;

//   span {
//     color: $warn-color !important;
//     text-transform: uppercase;
//   }
// }

.fine-letter {
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
  font-size: 15px !important;
  font-weight: 400 !important;
}

// .star-icon:before {
//   color: $main-color !important;
// }

.cursor-pointer {
  cursor: pointer;
}

.cdk-overlay-pane {
  max-width: 100vw !important;
}


.loading-shade-full {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.48) !important;
  z-index: 1024;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-expansion-panel-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

//for modals
.mat-dialog-container {
  padding-top: 0 !important;

  //padding-bottom: 0 !important;
  .mat-dialog-title {
    margin-top: 10px;
    width: 100% !important;
  }
}

.user-avatar {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
}

.mat-list-item-content {
  width: 100% !important;
}

//body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@media (max-width: 800px) {
  .responsive_table {
    overflow-x: auto !important;
  }

  div.md-drppicker {
    width: 300px !important;
  }

  .mat-table {
    min-width: 800px;
  }

  .mat-dialog-container {
    //padding-top: 0 !important;
    //padding-bottom: 0 !important;
  }
}

.height-100 {
  height: 100% !important;
}

.display-none {
  display: none;
}

.display-unset {
  display: unset;
}

// .ng5-slider .ng5-slider-pointer {
//   background-color: $main-color !important;
// }

// .ng5-slider .ng5-slider-selection {
//   background: $main-color !important;
// }

// .ng5-slider .ng5-slider-pointer.ng5-slider-active:after {
//   background-color: $main-color !important;
// }

// .md-drppicker .ranges ul li button.active {
//   background-color: $main-color !important;
//   color: #fff;
// }

// .date-range .btn {
//   background-color: $main-color !important;
// }

// .date-range .md-drppicker .btn {
//   background-color: $main-color !important;
// }

.btn.btn-default {
  background-color: $grey-100 !important;
}

// .md-drppicker .btn:focus,
// .md-drppicker .btn:hover {
//   background-color: $main-color !important;
// }

// .buttons .buttons_input .btn {
//   background-color: $main-color !important;
// }

// .md-drppicker td.active {
//   background-color: $main-color !important;
// }

.calendar-status-requested {
  padding: 3px 10px;
  border-bottom-left-radius: 5px;
  position: absolute;
  right: 0;
  background-color: $amber-500;
  color: white;
}

.calendar-status-approved {
  padding: 3px 10px;
  border-bottom-left-radius: 5px;
  position: absolute;
  right: 0;
  background-color: $blue-500;
  color: white;
}

.calendar-status-confirmed {
  padding: 3px 10px;
  border-bottom-left-radius: 5px;
  position: absolute;
  right: 0;
  background-color: $green-500;
  color: white;
}

.calendar-status-cancelled {
  padding: 3px 10px;
  border-bottom-left-radius: 5px;
  position: absolute;
  right: 0;
  background-color: $red-500;
  color: white;
}

.calendar-status-confirmed {
  padding: 3px 10px;
  border-bottom-left-radius: 5px;
  position: absolute;
  right: 0;
  background-color: $green-500;
  color: white;
}

.calendar-status-consumed,
.status-expired {
  padding: 3px 10px;
  border-bottom-left-radius: 5px;
  position: unset !important;
  right: 0;
  background-color: $grey-500;
  color: white;
}

button.mat-menu-item {
  line-height: 24px !important;
}

a.mat-menu-item > mat-icon {
  margin-bottom: 14px;
}

.mat-icon svg {
  height: 24px;
  width: 24px;
}

// RESERVATION BUTTONS
.approve-button {
  border: solid 2px $blue-500 !important;
  background-color: $blue-500 !important;

  span {
    color: white !important;
    text-transform: uppercase !important;
  }
}

.reject-button {
  border: solid 2px $red-500 !important;

  span {
    color: $red-500 !important;
    text-transform: uppercase !important;
  }
}

.show-details-button {
  span {
    text-transform: uppercase !important;
  }
}

// .timepicker__header {
//   background-color: $main-color !important;
// }

// .timepicker-button {
//   color: $main-color !important;
// }

.mat-stroked-button {
  border: solid 2px !important;
}

// ngx-material-timepicker-face {
//   span.active {
//     background-color: $main-color !important;
//   }

//   .clock-face__container {
//     background-color: $main-color !important;
//   }
// }

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $grey-400;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $grey-500;
}

// MAP

.si-has-border .si-content-wrapper {
  background-color: white !important;
}

agm-map {
  .infoBox {
    border-radius: 0;
    // width: 350px;
    width: 190px;
    padding: 5px;

    &:after {
      border-left: 0 solid transparent;
      border-right: 0 solid transparent;
      border-top: 0 solid #ffffff;
    }
  }

  .listing-img-container {
    width: 100% !important;
    height: auto !important;
    max-height: 15em;
    overflow: hidden;

    img {
      border-radius: 0 !important;
      width: 100%;
    }
  }

  .si-content {
    max-width: 100% !important;
    max-height: 100% !important;
  }

  .si-content-wrapper {
    padding: 0 !important;

    .si-close-button {
      // z-index: 2;
      // background-color: black !important;
      // color: white !important;
      // right: 5px;
      // top: 5px;

      z-index: 2;
      background-color: transparent !important;
      color: red !important;
      right: 5px;
      top: -2px;
      opacity: 1;
      font-size: 40px;
    }
  }

  .si-has-border .si-content-wrapper {
    border: 0 solid #bbbbbb !important;
    // background-color: transparent !important;
  }
}

.map-box {
  border-radius: 0 !important;
}

// VCA ----

.no-padding {
  mat-dialog-container {
    padding: 0;
  }
}


/*--------------------------------------------------
	[2. Breadcrumbs]
----------------------------------------------------*/
/*Default Breadcrumbs*/

.loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15) !important;
  z-index: 1024;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-shade-full {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.48) !important;
  z-index: 1024;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-snack-bar-container.error {
  color: white;
  border-radius: 4px !important;
  box-sizing: border-box !important;
  display: block !important;
  margin: 24px !important;
  max-width: 33vw !important;
  min-width: 344px !important;
  padding: 14px 16px !important;
  min-height: 54px !important;
  transform-origin: center !important;
  border: 1.8px solid #f44336 !important;

  .mat-simple-snackbar-action {
    color: #f44336 !important;
    padding: 2px !important;
  }

  .mat-simple-snackbar-action button {
    color: #f44336 !important;
    max-height: 36px;
    min-width: 36px;
    padding: 3px 0 !important;
    width: 45px;
    background-color: #323232 !important;
    opacity: 0.9 !important;

    &:hover {
      background-color: #292929 !important;
      transition: background-color .5s ease !important;

    }
  }
}

.mat-snack-bar-container.succes {
  color: white;
  border-radius: 4px !important;
  box-sizing: border-box !important;
  display: block !important;
  margin: 24px !important;
  max-width: 33vw !important;
  min-width: 344px !important;
  padding: 14px 16px !important;
  min-height: 54px !important;
  transform-origin: center !important;
  border: 1.8px solid #4caf50;

  .mat-simple-snackbar-action {
    color: #4caf50 !important;
  }
}

.header-components {
  padding: 20px;

  mat-icon {
    font-size: 30px;
    width: 30px;
    height: 30px;
  }

  span {
    font-size: 30px;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding: 16px !important;

    mat-icon {
      font-size: 24px !important;
      width: 24px !important;
      height: 24px !important;
    }

    span {
      font-size: 24px !important;
    }

  }
}

.border-left {
  border-left: solid 1.0px rgba(0, 0, 0, 0.14) !important;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
  ::ng-deep {
    .mat-header-cell {
      min-width: 150px !important;
    }
  }

}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.basic-container {
  max-width: 900px;
  padding: 0 1rem;
  margin: 0 auto;
}

.search-input {
  font-size: 20px;
  outline: none;
  border: 0;
  box-shadow: none;
  background-color: #fcfcfc;
  height: 100%;
  border-radius: 8px;
  padding: 8px;
}


.crossed {
  background: linear-gradient(to top left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%),
  linear-gradient(to top right,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) calc(50% - 0.8px),
          rgba(0, 0, 0, 1) 50%,
          rgba(0, 0, 0, 0) calc(50% + 0.8px),
          rgba(0, 0, 0, 0) 100%);
}

.swiper-pagination-bullet-active {
  background-color: rgba(245, 245, 245, 0.5) !important;
}


blockquote {
  background: #f9f9f9;
  font-size: 1em;
  border-left: 10px solid $red-500;;
  margin: 0.4em 0 1em;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}

blockquote:before {
  color: $red-500;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
  font-weight: 300;
  font-size: 1.3em;
}

button.swipe-arrow {
  position: absolute;
  top: 50%;
  margin-top: -22px;
  z-index: 10;
  background-image: none;
}

.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}

.swiper-button-next {
  background-color: rgba(245, 245, 245, 0.5) !important;
}

.swiper-button-prev {
  background-color: rgba(245, 245, 245, 0.5) !important;
}

.text-align-center {
  text-align: center !important;
}

.regimen-label {
  background-color: $accent-500;
  color: white;
  border-radius: 12px;
  padding: 3px 15px;
  margin-bottom: 10px;
}

blockquote {
  background: #f9f9f9;
  font-size: 1em;
  border-left: 10px solid $red-500;;
  margin: 0.4em 0 1em;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}

blockquote:before {
  color: $red-500;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
  font-weight: 300;
  font-size: 1.3em;
}

.info-div {
  background-color: white !important;
  border-radius: 16px !important;
  position: fixed !important;
  bottom: -100px !important;
  right: -100px !important;
  z-index: 9999 !important;
  width: 0;
  height: 0;
  transition: all 1s ease;

  .small-button {
    // width: 30px !important;
    // max-width: 30px !important;
    line-height: 30px;
    text-align: center;
    background-color: $main-color;
    color: white;
    padding: 0 25px;
    font-size: 12px;
    border-radius: 16px;
    text-transform: uppercase;
  }

  .line-clamp-info {
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    line-height: 1rem !important;
    height: 2rem !important;
    overflow: hidden;
  }
}

.info-panel-show {
  bottom: 20px !important;
  right: 10px !important;
  width: 250px !important;
  height: 70px;
  border: solid 1px $main-color !important;
  padding: 5px 10px !important;
  transition: width 1s ease, height 1s ease;
}

.info-panel-show-pay {
  bottom: 20px !important;
  right: 10px !important;
  width: 250px !important;
  height: 110px;
  border: solid 1px $main-color !important;
  padding: 5px 10px !important;
  transition: width 1s ease, height 1s ease;
}

.change-location-label {
  background-color: $main-color !important;
  color: white !important;
  height: 30px;
  line-height: 30px;
  border-radius: 31px;
  padding-left: 10px;
  padding-right: 10px;
}

.disabled-location-label {
  background-color: $warn-color !important;
  color: white !important;
  height: 30px;
  line-height: 30px;
  border-radius: 31px;
  padding-left: 10px;
  padding-right: 10px;
}

.custom-select-button {
  .mat-form-field-appearance-outline:hover .mat-form-field-outline-thick:hover {
    color: transparent !important;
  }

  .mat-form-field-outline {
    border: solid 2px $main-color;
    border-radius: 6px;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
    // background-color: $main-color;
    // border-radius: 6px;
  }

  .mat-form-field-infix {
    padding-top: 7px !important;
  }

  .mat-select-arrow {
    color: $main-color !important;
  }

  .mat-select-value {
    color: $main-color !important;
    text-transform: uppercase !important;
  }

  .mat-select-placeholder {
    color: $main-color !important;
    font-weight: 600 !important;
  }
}

.custom-select-button:hover {
  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: transparent !important;
  }
}

.remove-padding-bottom > div.mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.mat-form-field {
  font-size: 14px !important;
}

.p-1_5rem {
  padding: 1.5rem !important;
}

.h3, h3 {
  line-height: 1.5;
  font-weight: 700 !important;
  font-size: 1.75rem;
}

.timepicker-overlay {
  z-index: 1100 !important;
}

.timepicker-backdrop-overlay {
  z-index: 1100 !important;
}

.mat-menu-panel {
  min-width: 200px !important;
}

mat-sidenav {
  width: 240px;
  padding-top: 0;
}

#snav {
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);

  .mat-list-item {
    height: auto;

    &:hover {
      background: none;
    }

    .mat-list-item-content {
      display: block;
      padding: 0 15px;

      &:hover {
        background: none;

        > a {
          color: $themecolor;
        }
      }
    }

    a {
      height: 45px;
      padding: 0 10px;
      margin-bottom: 10px;
      align-items: center;
      display: flex;
      font-size: 15px;
      white-space: nowrap;
      color: $sidebar-text;

      mat-icon:not(.dd-icon) {
        margin-right: 8px;
      }

      mat-icon {
        color: $sidebar-icons;
      }

      .dd-icon {
        font-size: 16px;
        width: 16px;
        transition: 0.2s ease-in;
        margin: 5px 0 5px 5px;
        height: 16px;
      }
    }

    &.selected > .mat-list-item-content > a {
      background: $themecolor-alt;
      border-radius: $radius;
      color: $white;

      mat-icon {
        color: $white;
      }

      .dd-icon {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
      }

    }

    &.selected .sub-item .mat-list-item-content a.selected {
      background: transparent;
      color: $inverse;
      font-weight: 500;
    }
  }

  .sub-item {
    display: none;
    margin-top: -15px;

    a {
      padding-left: 28px;
      height: 50px;
      margin-bottom: 0;
    }
  }

  .saperator {
    cursor: text;
    font-size: 13px;
    margin-bottom: 15px;
    margin-top: 25px;
    font-weight: 500;
    white-space: nowrap;
  }

  .selected {
    .sub-item {
      display: block;
    }
  }
}

/*******************
use profile section
******************/

.user-profile {

  position: relative;
  background-size: cover;

  .profile-img {
    width: 50px;
    margin-left: 30px;
    padding: 31px 0;
    border-radius: 100%;

    &::before {
      -webkit-animation: 2.5s blow 0s linear infinite;
      animation: 2.5s blow 0s linear infinite;
      position: absolute;
      content: '';
      width: 50px;
      height: 50px;
      top: 31px;
      margin: 0 auto;
      border-radius: 50%;
      z-index: 0;
    }

    @-webkit-keyframes blow {
      0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
      50% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
      100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
        opacity: 0;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
    }
    @keyframes blow {
      0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
      50% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
      100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
        opacity: 0;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
    }

    img {
      width: 100%;
      border-radius: 100%;
    }
  }

  .profile-info {
    padding: 10px 5px 10px 31px;
    color: white;
    background: rgba(0, 0, 0, 0.5);
  }

  .profile-text {
    padding: 5px 0;
    position: relative;
    cursor: pointer;
    white-space: nowrap;

    > a {
      color: $white !important;
      width: 100%;
      padding: 6px 30px;
      background: rgba(0, 0, 0, 0.5);
      display: block;
      white-space: nowrap;

      &:after {
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }
  }

}

/*==============================================================
 Small Desktop & above all (1024px)
 ============================================================== */

@media(min-width: 1024px) {
  .minisidebar #snav {
    .mat-drawer-backdrop {
      display: none;
    }

    &.mat-sidenav ~ .mat-drawer-content {
      margin-left: $mini-sidebar-width;
    }

    &.mat-sidenav {
      -webkit-transition: width .2s cubic-bezier(.25, .8, .25, 1), transform .2s cubic-bezier(.25, .8, .25, 1) !important;
      transition: width .2s cubic-bezier(.25, .8, .25, 1), transform .2s cubic-bezier(.25, .8, .25, 1) !important;
    }

    &.mat-sidenav:not(:hover) {
      width: $mini-sidebar-width;

      .sub-menu, .dd-icon, .selected .sub-item {
        display: none;
        visibility: hidden;
      }

      .user-profile {
        .profile-img {
          margin-left: 15px;
        }

        .profile-text {
          visibility: hidden;
        }
      }

      .saperator span {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 40px;
        display: block;
      }

      .mat-list-item > .mat-list-item-content > a {
        padding-right: 0;
        padding-left: 0;
        text-align: center;

      }

      .mat-list-item > .mat-list-item-content > a > span:not(.menu-badge),
      .mat-list-item > .mat-list-item-content > a > .menu-caret {
        display: none;
      }

      .mat-list-item > .mat-list-item-content > a > span.menu-badge {
        position: absolute;
        top: 10px;
        right: 16px;
      }

      .mat-list-item > .mat-list-item-content > a > .material-icons {
        width: $mini-sidebar-width !important;
        padding: 0;
        margin: 0;
      }
    }
  }

  [dir="rtl"] {
    app-sidebar {
      display: block;
      overflow: hidden;
    }

    .minisidebar #snav.mat-sidenav ~ .mat-drawer-content {
      margin-left: 0 !important;
      margin-right: $mini-sidebar-width !important;
    }
  }
}

.sidebar-closed #snav {
  &.mat-sidenav ~ .mat-drawer-content {
    margin-left: 0 !important;
  }
}
