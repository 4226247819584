////
/// Bottom (Automatic)
/// @author Annaliet Iglesias
/// @access public
/// @group cf-helpers
////

/* Bottom (Automatic)
 -------------------------------------------------------------- */

$i: 0;

@while $i <= $base-total-bottom {
  .#{$prefix-helpers}-bottom-#{$i} {
    bottom: #{$i}px;
  }
  $i: $i + $base-interval-bottom;
}


// Example Generate:
//
//.cf-bottom-0 {
//  bottom: 0 !important;
//}
//
//.cf-bottom-10 {
//  bottom: 10px !important;
//}
//
//.cf-bottom-20 {
//  bottom: 20px !important;
//}
//
//.cf-bottom-30 {
//  bottom: 30px !important;
//}
//
//.cf-bottom-40 {
//  bottom: 40px !important;
//}
