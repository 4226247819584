$spaceAmounts: (2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 36, 38, 40, 42, 48, 50, 52, 54, 58, 60); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

%cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

@each $space in $spaceAmounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

$intervals: (4, 8, 16, 32, 48);

@for $i from 1 through length($intervals) {
  $c: nth($intervals, $i);

  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$i} {
      margin-#{$side}: #{$c}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$i} {
      padding-#{$side}: #{$c}px !important;
    }
  }

  .p-#{$i} {
    padding: #{$c}px !important;
  }

  .px-#{$i} {
    padding-left: #{$c}px !important;
    padding-right: #{$c}px !important;
  }

  .py-#{$i} {
    padding-top: #{$c}px !important;
    padding-bottom: #{$c}px !important;
  }

  .m-#{$i} {
    margin: #{$c}px !important;
  }

  .mx-#{$i} {
    margin-left: #{$c}px !important;
    margin-right: #{$c}px !important;
  }

  .my-#{$i} {
    margin-top: #{$c}px !important;
    margin-bottom: #{$c}px !important;
  }
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@function nonPercent($str) {
  @while (str-index($str, "%") !=null) {
    $index: str-index($str, "%");
    $str: "#{str-slice($str, 0, $index - 1)}#{str-slice($str, $index + 1)}";
  }

  @return $str;
}

$intervalWith: ("0%", "5%", "10%", "15%", "20%", "25%", "30%", "35%", "40%", "45%", "48%", "50%", "55%", "60%", "65%", "70%", "75%", "80%", "85%", "90%", "95%", "100%"); // Adjust this to include the pixel amounts you need.

@each $with in $intervalWith {
  .w-#{nonPercent($with)} {
    width: #{$with} !important;
  }

  .h-#{nonPercent($with)} {
    height: #{$with} !important;
  }
}

@function nonPX($str) {
  @while (str-index($str, "p") !=null) {
    $index: str-index($str, "p");
    $str: "#{str-slice($str, 0, $index - 1)}#{str-slice($str, $index + 1)}";
  }

  @while (str-index($str, "x") !=null) {
    $index: str-index($str, "x");
    $str: "#{str-slice($str, 0, $index - 1)}#{str-slice($str, $index + 1)}";
  }

  @return $str;
}

$intervalPixels: ("1px", "2px", "3px", "4px", "5px", "6px", "7px", "8px", "9px", "10px", "11px", "12px", "13px", "14px", "15px", "16px", "17px", "18px", "19px", "20px");

@each $pixels in $intervalPixels {
  .fs-#{nonPX($pixels)} {
    font-size: #{$pixels} !important;
  }

  .fs-#{nonPX($pixels)} {
    font-size: #{$pixels} !important;
  }
}

.container {
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
