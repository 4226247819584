////
/// Right (Automatic)
/// @author Annaliet Iglesias
/// @access public
/// @group cf-helpers
////

/* Right (Automatic)
 -------------------------------------------------------------- */

$i: 0;

@while $i <= $base-total-right {
  .#{$prefix-helpers}-right-#{$i} {
    right: #{$i}px;
  }
  $i: $i + $base-interval-right;
}


// Example Generate:
//
//.cf-right-0 {
//  right: 0 !important;
//}
//
//.cf-right-10 {
//  right: 10px !important;
//}
//
//.cf-right-20 {
//  right: 20px !important;
//}
//
//.cf-right-30 {
//  right: 30px !important;
//}
//
//.cf-right-40 {
//  right: 40px !important;
//}
