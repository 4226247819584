/* CF HELPERS - Inspired by css-helpers (https://github.com/ahmadajmi/css-helpers)
 -------------------------------------------------------------- */

// VARIABLES

@import '_variables.scss';

// HELPERS

//@import 'functions/_float.scss';
//@import 'functions/_clearfix.scss';
//@import 'functions/_z-index.scss';
//@import 'functions/_positions.scss';
//@import 'functions/_width.scss';
//
@import 'functions/_align.scss';
//@import 'functions/_transform.scss';
//@import 'functions/_display.scss';
//@import 'functions/_cursor.scss';
//
//@import 'functions/_colors.scss';
//@import 'functions/_backgrounds.scss';
//
//@import 'functions/_typography.scss';
//@import 'functions/_lists.scss';
//@import 'functions/_visibility.scss';
//@import 'functions/_border.scss';
//@import 'functions/_border-radius.scss';

@import 'functions/_margin.scss';
@import 'functions/_padding.scss';
@import 'functions/_top.scss';
@import 'functions/_bottom.scss';
@import 'functions/_left.scss';
@import 'functions/_right.scss';