@import '@angular/material/theming';

@mixin layout-theme($primary, $primary-100) {
  app-layout-hotels {
    .module-item:hover {
      background-color: $primary-100;
    }

    .default-avatar {
      background-color: $primary-100;
      color: $primary;
    }

    .mat-sidenav {
      background-color: $primary;
    }

    .active-link {
      background-color: #e0f2f2;

      .mat-list-item-content {
        color: $primary !important;

        span {
          color: $primary !important;
        }

        .mat-icon {
          color: $primary;
        }
      }
    }

    .active-link:hover {
      background-color: #e0f2f2 !important;

      .mat-list-item-content {
        color: $primary !important;

        span {
          color: $primary !important;
        }

        .mat-icon {
          color: $primary;
        }
      }
    }

    .credit-info {
      color: $primary;
    }

    @media only screen and (min-width: 480px) and (max-width: 850px) {
      .credit-info {
        color: $primary;
      }
    }

    .backend-default-avatar {
      background-color: $primary-100;
      color: $primary;
    }

  }

}
